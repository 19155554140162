<template>
  <div class="container">
    <strong>ตั้งค่า SEO</strong>
    <div class="card position-relative">
      <div class="card-body">
        <form
          id="seo-form"
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >

          <b-form-group
            label="Title"
          >
            <b-form-input
              v-model="input.data.title"
              type="text"
              placeholder="ชื่อเว็บไซต์"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Meta Keywords"
          >
            <b-form-input
              v-model="input.data.metaKeywords"
              type="text"
              placeholder="คำค้นหา"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Meta Description"
          >
            <b-form-input
              v-model="input.data.metaDescription"
              type="text"
              placeholder="รายละเอียดเว็บไซต์"
            ></b-form-input>
          </b-form-group>

          <div class="d-flex justify-content-end mt-3 pt-3">
            <b-button type="submit" variant="primary" size="sm" :disabled="isLoading">{{isLoading ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
          </div>
        </form>
      </div>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </div>
</template>
<script>
import SettingService from '@/services/settingService'
import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'SettingSeo',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      settingId: null,
      input: {
        type: 'meta-seo',
        data: {
          title: '',
          metaKeywords: '',
          metaDescription: ''
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      this.isLoading = true
      SettingService.saveSetting(this.settingId, this.input)
      .then((response)=>{
        if(response.success) {
          this.loadSetting()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e ? e.message : 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    loadSetting() {
      this.isLoading = true
      SettingService.getSetting(this.input.type)
      .then((response)=>{
        if(response.success) {
          this.settingId = response.data?.[0]._id || null
          this.input.data = response.data?.[0].data || {
            title: '',
            metaKeywords: '',
            metaDescription: ''
          }
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.loadSetting()
  }
}
</script>
